<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner" id="auth-inner">
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <!-- logo -->
            <v-card-title class="d-xs-flex align-center justify-center py-7">
              <router-link
                to="/"
                class="d-flex align-center"
              >
                <v-img
                  :src="require('@/assets/images/pages/kisspng-logistics.png')"
                  max-width="700"
                  alt="logo"
                  contain
                  class="me-3"
                  style="vertical-align: middle;"
                ></v-img>
              </router-link>
            </v-card-title>

          </v-col>
          <v-col
            cols="12"
            md="5"
          >
          
            <v-card class="auth-card">
              <!-- logo -->
                <v-card-title class="d-flex align-center justify-center py-7">
                  <router-link
                    to="/"
                    class="d-flex align-center"
                  >
                    <v-img
                      :src="require('@/assets/images/logos/logo.png')"
                      max-height="80px"
                      max-width="auto"
                      alt="logo"
                      contain
                      class="me-3 "
                    ></v-img>
                  </router-link>
                </v-card-title>
                <!-- title -->
                <v-card-text>
                  <p class="text-2xl text-center font-weight-semibold text--primary mb-2">
                    {{ $t('tracking.welcome') }}
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form>
                      <v-textarea
                        name="input-7-1"
                        v-model="tracking"
                        filled
                        :label="$t('tracking.number')"
                        auto-grow
                        value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                        >
                    </v-textarea>

                    <v-btn
                      block
                      color="primary"
                      class="mt-6"
                    >
                      {{ $t('tracking.search') }}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- divider
                <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">
                    {{ $t('login.or') }}</span>
                  <v-divider></v-divider>
                </v-card-text>

                social links 
                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    v-for="link in socialLink"
                    :key="link.icon"
                    icon
                    class="ms-1"
                  >
                    <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>  -->
            
            </v-card>
          </v-col>
        </v-row> 
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import router from "../../router";
import {Drequest} from "../../plugins/Drequest";

export default {
  setup() {
    const tracking = ref('')
    const itemOrder = ref({})

    const route = router.currentRoute
    Drequest.api("detail.order?id=" + route.params.id)
        .get((response) => {
          itemOrder.value = response.transporter;

        })
    return {
      tracking,
      itemOrder,
      icons: {

      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
